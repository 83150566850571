var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "font-bold text-subtitle-1"
  }, [_vm._v(_vm._s(_vm.feature.name))]), _c('v-card-text', [_c('v-row', [_vm._l(_vm.feature.attributes, function (attribute, index) {
    return [attribute.hasChild || !attribute.parentId ? _c('v-col', {
      key: index,
      staticClass: "py-1",
      attrs: {
        "cols": _vm.getBreakpoint(attribute),
        "sm": _vm.getBreakpoint(attribute),
        "md": _vm.getBreakpoint(attribute)
      }
    }, [attribute.hasChild ? _c('div', [_c('span', {
      staticClass: "font-bold text-subtitle-1"
    }, [_vm._v(_vm._s(attribute.name))]), _c('v-row', _vm._l(_vm.feature.child[attribute._id], function (attribute, index) {
      return _c('v-col', {
        key: index,
        staticClass: "py-1",
        attrs: {
          "cols": "6",
          "sm": "6",
          "md": "6"
        }
      }, [attribute.fieldType == 'number' ? _c('div', {
        staticClass: "pt-1"
      }, [_c('vx-input', {
        attrs: {
          "type": "number",
          "label": attribute.name,
          "default": 0,
          "hide-details": ""
        },
        model: {
          value: _vm.attributes[attribute._id].value,
          callback: function callback($$v) {
            _vm.$set(_vm.attributes[attribute._id], "value", $$v);
          },
          expression: "attributes[attribute._id].value"
        }
      })], 1) : attribute.fieldType == 'text' ? _c('div', {
        staticClass: "pt-1"
      }, [_c('vx-input', {
        attrs: {
          "type": "text",
          "label": attribute.name,
          "hide-details": ""
        },
        model: {
          value: _vm.attributes[attribute._id].value,
          callback: function callback($$v) {
            _vm.$set(_vm.attributes[attribute._id], "value", $$v);
          },
          expression: "attributes[attribute._id].value"
        }
      })], 1) : _c('v-checkbox', {
        attrs: {
          "label": attribute.name,
          "hide-details": "",
          "dense": ""
        },
        model: {
          value: _vm.attributes[attribute._id].value,
          callback: function callback($$v) {
            _vm.$set(_vm.attributes[attribute._id], "value", $$v);
          },
          expression: "attributes[attribute._id].value"
        }
      })], 1);
    }), 1)], 1) : !attribute.parentId ? _c('div', [attribute.fieldType == 'text' ? _c('div', {
      staticClass: "pt-1"
    }, [_c('vx-input', {
      attrs: {
        "type": "text",
        "label": attribute.name,
        "hide-details": ""
      },
      model: {
        value: _vm.attributes[attribute._id].value,
        callback: function callback($$v) {
          _vm.$set(_vm.attributes[attribute._id], "value", $$v);
        },
        expression: "attributes[attribute._id].value"
      }
    })], 1) : attribute.fieldType == 'textarea' ? _c('div', {
      staticClass: "pt-1"
    }, [_c('textarea-field', {
      attrs: {
        "label": attribute.name,
        "hide-details": ""
      },
      model: {
        value: _vm.attributes[attribute._id].value,
        callback: function callback($$v) {
          _vm.$set(_vm.attributes[attribute._id], "value", $$v);
        },
        expression: "attributes[attribute._id].value"
      }
    })], 1) : attribute.fieldType == 'number' ? _c('div', {
      staticClass: "pt-1"
    }, [_c('vx-input', {
      attrs: {
        "type": "number",
        "label": attribute.name,
        "default": 0,
        "hide-details": ""
      },
      model: {
        value: _vm.attributes[attribute._id].value,
        callback: function callback($$v) {
          _vm.$set(_vm.attributes[attribute._id], "value", $$v);
        },
        expression: "attributes[attribute._id].value"
      }
    })], 1) : attribute.fieldType == 'wysiwyg' ? _c('div', {
      staticClass: "pt-1"
    }, [_c('span', {
      staticClass: "font-bold text-subtitle-1"
    }, [_vm._v(_vm._s(attribute.name))]), _c('quill-editor', {
      attrs: {
        "placeholder": attribute.placeholder
      },
      model: {
        value: _vm.attributes[attribute._id].value,
        callback: function callback($$v) {
          _vm.$set(_vm.attributes[attribute._id], "value", $$v);
        },
        expression: "attributes[attribute._id].value"
      }
    })], 1) : _c('v-checkbox', {
      attrs: {
        "label": attribute.name,
        "hide-details": "",
        "dense": ""
      },
      model: {
        value: _vm.attributes[attribute._id].value,
        callback: function callback($$v) {
          _vm.$set(_vm.attributes[attribute._id], "value", $$v);
        },
        expression: "attributes[attribute._id].value"
      }
    })], 1) : _vm._e()]) : _vm._e()];
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }