<template>
  <div>
    <ContentList :options="options">
      <v-card-title> Created here </v-card-title>

      <v-card-text>
        The purpose of this section is to showcase how people have used your space in the past, so people get a sense of
        what is possible.
        <b />
        Please share the public links of any content that was created here. This can include: YouTube, Soundcloud,
        Flickr, Vimeo, and any other links you wish to share.
      </v-card-text>
    </ContentList>
  </div>
</template>

<script>
import { VENUE_TYPE } from '@tutti/constants';

export default {
  name: 'VenueCreatedHere',

  components: {
    ContentList: () => import('@admin/views/venue/edit/components/contentList.vue'),
  },

  props: {
    space: { type: Object, required: true },
    venueType: { type: String, required: true },
  },

  data() {
    return {
      options: {
        isVerified: true,
        filter: {
          isVerified: true,
        },
        contentOptions: {},
      },
    };
  },

  created() {
    if (this.venueType === VENUE_TYPE.LOCATION) {
      this.options.filter.locationId = this.venue._id;
    } else if (this.venueType === VENUE_TYPE.SPACE) {
      this.options.filter.spaceId = this.space._id;
      // this.options.filter.locationId = this.venue.locationId?._id;
      // this.options.contentOptions.displayInLocation = true;
    }

    this.options.filter.hostAccount = this.space.accountId?._id;
  },
};
</script>
