<template>
  <v-row>
    <!-- odd sort will display at the left -->
    <v-col cols="12" sm="6" md="6">
      <slot name="left"></slot>

      <template v-for="(feature, index) in features">
        <SpaceFeature
          v-if="feature.sort % 2 != 0 && !feature.hideFrom.create"
          :key="index"
          :space="space"
          :feature="feature"
          :attributes="attributes"
        />
      </template>
    </v-col>

    <!-- even sort will display at the right -->
    <v-col cols="12" sm="6" md="6">
      <template v-for="(feature, index) in features">
        <SpaceFeature
          v-if="feature.sort % 2 == 0 && !feature.hideFrom.create"
          :key="index"
          :space="space"
          :feature="feature"
          :attributes="attributes"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import SpaceFeature from './SpaceFeature';

export default {
  name: 'SpaceFeatures',
  components: {
    SpaceFeature,
  },
  props: {
    space: { type: Object, required: false },
    attributes: { type: Object, default: () => ({}) },
    features: { type: Array, required: true },
  },
};
</script>
