var render = function () {
  var _vm$space$preferences, _vm$space$preferences2, _vm$space$preferences3, _vm$space$preferences4, _vm$space$preferences5, _vm$space$preferences6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    model: {
      value: _vm.space.name,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "name", $$v);
      },
      expression: "space.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": ""
    },
    model: {
      value: _vm.space.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "slug", $$v);
      },
      expression: "space.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "label": "Profile",
      "disabled": ""
    },
    model: {
      value: _vm.space.account.name,
      callback: function callback($$v) {
        _vm.$set(_vm.space.account, "name", $$v);
      },
      expression: "space.account.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "item-text": "name",
      "item-value": "_id",
      "items": _vm.locations,
      "name": "locationId",
      "label": "Location",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeLocation();
      }
    },
    model: {
      value: _vm.space._locationId,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "_locationId", $$v);
      },
      expression: "space._locationId"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "multiple": "",
      "item-text": "name",
      "item-value": "_value",
      "items": _vm.spaceTypes,
      "name": "spaceTypes",
      "label": "Space types"
    },
    model: {
      value: _vm.space.types,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "types", $$v);
      },
      expression: "space.types"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "multiple": "",
      "item-text": "name",
      "item-value": "_value",
      "items": _vm.locationTypes,
      "name": "locationTypes",
      "label": "Location types"
    },
    model: {
      value: _vm.space.locationTypes,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "locationTypes", $$v);
      },
      expression: "space.locationTypes"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "multiple": "",
      "items": _vm.disciplines,
      "item-text": "name",
      "item-value": "_value",
      "name": "disciplines",
      "label": "Disciplines",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeDisciplines();
      }
    },
    model: {
      value: _vm.space.disciplines,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "disciplines", $$v);
      },
      expression: "space.disciplines"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "multiple": "",
      "items": _vm.parentActivities,
      "item-text": "name",
      "item-value": "_value",
      "name": "activities",
      "label": "Activities",
      "required": ""
    },
    model: {
      value: _vm.space.activities,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "activities", $$v);
      },
      expression: "space.activities"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "items": _vm.primaryUses,
      "item-text": "name",
      "item-value": "_value",
      "name": "primaryUse",
      "label": "Primary use"
    },
    model: {
      value: _vm.space.primaryUse,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "primaryUse", $$v);
      },
      expression: "space.primaryUse"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "items": _vm.secondaryUses,
      "item-text": "name",
      "item-value": "_value",
      "name": "secondaryUse",
      "label": "Secondary use"
    },
    model: {
      value: _vm.space.secondaryUse,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "secondaryUse", $$v);
      },
      expression: "space.secondaryUse"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "commission",
      "label": "Marketplace Commission",
      "required": ""
    },
    model: {
      value: _vm.space.commission,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "commission", $$v);
      },
      expression: "space.commission"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "commission",
      "label": "Partners commission",
      "required": ""
    },
    model: {
      value: _vm.space.manualPaymentCommission,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "manualPaymentCommission", $$v);
      },
      expression: "space.manualPaymentCommission"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "color",
      "label": "Color",
      "required": ""
    },
    model: {
      value: _vm.space.color,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "color", $$v);
      },
      expression: "space.color"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_c('quill-editor', {
    staticClass: "mb-4",
    model: {
      value: _vm.space.description,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "description", $$v);
      },
      expression: "space.description"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('v-card', {
    staticClass: "h-100",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "py-1 font-bold text-subtitle-1"
  }, [_vm._v("Preferences")]), _c('v-card-subtitle', {
    staticClass: "mt-2 font-bold text-subtitle-1"
  }, [_vm._v("Booking System")]), _c('v-card-text', [_c('v-switch', {
    staticClass: "mb-4",
    attrs: {
      "hide-details": "",
      "label": "Has External Link"
    },
    model: {
      value: _vm.space.preferences.bookingSystem.hasPrivateService,
      callback: function callback($$v) {
        _vm.$set(_vm.space.preferences.bookingSystem, "hasPrivateService", $$v);
      },
      expression: "space.preferences.bookingSystem.hasPrivateService"
    }
  }), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "mb-0 pb-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "disabled": !((_vm$space$preferences = _vm.space.preferences) !== null && _vm$space$preferences !== void 0 && (_vm$space$preferences2 = _vm$space$preferences.bookingSystem) !== null && _vm$space$preferences2 !== void 0 && _vm$space$preferences2.hasPrivateService),
      "type": "text",
      "name": "btnText",
      "label": "Button Text"
    },
    model: {
      value: _vm.space.preferences.bookingSystem.btnText,
      callback: function callback($$v) {
        _vm.$set(_vm.space.preferences.bookingSystem, "btnText", $$v);
      },
      expression: "space.preferences.bookingSystem.btnText"
    }
  })], 1), _c('v-col', {
    staticClass: "mb-0 pb-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "disabled": !((_vm$space$preferences3 = _vm.space.preferences) !== null && _vm$space$preferences3 !== void 0 && (_vm$space$preferences4 = _vm$space$preferences3.bookingSystem) !== null && _vm$space$preferences4 !== void 0 && _vm$space$preferences4.hasPrivateService),
      "type": "text",
      "name": "redirectUrl",
      "label": "Redirect URL"
    },
    model: {
      value: _vm.space.preferences.bookingSystem.redirectUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.space.preferences.bookingSystem, "redirectUrl", $$v);
      },
      expression: "space.preferences.bookingSystem.redirectUrl"
    }
  })], 1), _c('v-col', {
    staticClass: "mb-0 pb-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "disabled": !((_vm$space$preferences5 = _vm.space.preferences) !== null && _vm$space$preferences5 !== void 0 && (_vm$space$preferences6 = _vm$space$preferences5.bookingSystem) !== null && _vm$space$preferences6 !== void 0 && _vm$space$preferences6.hasPrivateService),
      "type": "color",
      "name": "btnColor",
      "label": "Button color"
    },
    model: {
      value: _vm.space.preferences.bookingSystem.btnColor,
      callback: function callback($$v) {
        _vm.$set(_vm.space.preferences.bookingSystem, "btnColor", $$v);
      },
      expression: "space.preferences.bookingSystem.btnColor"
    }
  })], 1)], 1)], 1), _c('v-card-subtitle', {
    staticClass: "py-0 font-bold text-subtitle-1"
  }, [_vm._v("Seo")]), _c('v-card-text', [_c('v-switch', {
    attrs: {
      "hide-details": "",
      "label": "No index"
    },
    model: {
      value: _vm.space.seo.noindex,
      callback: function callback($$v) {
        _vm.$set(_vm.space.seo, "noindex", $$v);
      },
      expression: "space.seo.noindex"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }