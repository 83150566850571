<template>
  <div>
    <v-card outlined class="mb-4">
      <v-card-title class="font-bold text-subtitle-1">Dimensions (in meters)</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="4" sm="4" md="4">
            <vx-input v-model="space.dimension.width" type="number" label="Width" v-bind="$attrs" />
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <vx-input v-model="space.dimension.length" type="number" label="Length" v-bind="$attrs" />
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <vx-input v-model="space.dimension.height" type="number" label="Height" v-bind="$attrs" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <SpaceFeatures :attributes="space._attributes" :features="features" />
  </div>
</template>

<script>
import { PRICE_MODELS } from '@tutti/constants';
import SpaceFeatures from './SpaceFeatures';

export default {
  components: {
    SpaceFeatures,
  },
  props: {
    space: { type: Object, required: true },
    features: { type: Array, required: true },
  },
  data() {
    return {
      PRICE_MODELS,
    };
  },
};
</script>
