<template>
  <v-form ref="form" lazy-validation>
    <v-card v-if="created">
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" @click="preview = true">Preview</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-tabs v-model="tab" :show-arrows="$vuetify.breakpoint.xs">
          <v-tab>Basic</v-tab>
          <v-tab>Photo</v-tab>
          <v-tab>Price</v-tab>
          <v-tab>Availability</v-tab>
          <v-tab>General</v-tab>
          <v-tab>Creative</v-tab>
          <!-- <v-tab>Covid</v-tab> -->
          <v-tab>Created Here</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <BasicInfo :space="space" :locations="locations" :accounts="accounts" @change-account="getLocations()" />
          </v-tab-item>

          <v-tab-item>
            <Photo :space="space" venue-type="space" />
          </v-tab-item>

          <v-tab-item>
            <Price :space="space" venue-type="space" />
          </v-tab-item>

          <v-tab-item>
            <Availability :space="space" />
          </v-tab-item>

          <v-tab-item>
            <GeneralInfo :space="space" :features="generalFeatures" />
          </v-tab-item>

          <v-tab-item>
            <CreativeInfo :space="space" :features="creativeFeatures" />
          </v-tab-item>

          <!-- <v-tab-item>
            <CovidInfo :space="space" :feature="covidFeature" />
          </v-tab-item> -->
          <v-tab-item>
            <CreatedHere :space="space" venue-type="space" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <vx-dialog v-model="preview" :title="space._url" height="90vh" width="90%">
      <iframe :src="space._url" style="width: 100%; height: 75vh"> </iframe>
    </vx-dialog>
  </v-form>
</template>

<script>
import GeneralInfo from './general';
import CreativeInfo from './creative';
//import CovidInfo from './covid';
import Price from '@admin/views/venue/edit/components/price.vue';
import Photo from '@admin/views/venue/edit/components/photo.vue';
import Availability from './availability';
import BasicInfo from './basic';
import CreatedHere from './createdHere';

import { SpaceService, LocationService, AccountService } from '@tutti/services';
import { Space } from '@tutti/models';
import { FeatureMixin } from '@tutti/mixins';

export default {
  components: {
    Price,
    Photo,
    BasicInfo,
    Availability,
    GeneralInfo,
    // CovidInfo,
    CreativeInfo,
    CreatedHere,
  },
  mixins: [FeatureMixin],

  data: () => ({
    tab: 0,
    created: false,
    preview: false,
    space: new Space(),
    locations: [],
    accounts: [],
    loading: false,
    covidFeature: {},
  }),

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getSpace();
    await this.getLocations();
    //await this.getAccounts();  // Profile can't be change, So that this call commented

    this.$setFeatures({
      generalFeatures: true,
      creativeFeatures: true,
    });

    this.covidFeature = this.getFeatureBySlug('precautions_taken');
    this.space.initAttributes(this.features);

    this.created = true;
  },

  methods: {
    async getLocations() {
      if (this.space._accountId) {
        const response = await LocationService.getNames({
          status: 'open,closed',
          accountId: this.space._accountId,
        });

        if (response) {
          this.locations = response.data;
        }
      }
    },

    async getAccounts() {
      const response = await AccountService.getAll({
        filter: { userType: 'host' },
      });

      if (response) {
        this.accounts = response.data.data;
      }
    },

    async getSpace() {
      if (this.$route.params.id) {
        const response = await SpaceService.getByIdAdmin(this.$route.params.id);
        if (response) {
          this.space = new Space(response.data);
          this.space.isNew = false;
          this.space._fromAdmin = true;
          //this.space.covid = {};
          this.space._url = `${this.artistURL}/${this.space.accountId.slug}/${this.space.locationId?.slug}/${this.space.slug}`;
          this.space.initAttributes(this.features);
          this.$setTitle(this.space.name);
        }
      } else {
        const response = await SpaceService.getId();
        if (response) {
          this.space._id = response.data.id;
          this.space._fromAdmin = true;
          //this.space.covid = {};
          this.space.initAttributes(this.features);
          return true;
        }
      }
    },

    async save(previous, status) {
      this.loading = true;

      if (status) {
        this.space.status = 1;
      }

      const space = this.space.get();

      const response = await SpaceService.patch(space);
      if (response && previous) {
        this.$previous();
      }
      this.loading = false;
    },
  },
};
</script>
