var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "font-bold text-subtitle-1"
  }, [_vm._v("Dimensions (in meters)")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "4",
      "md": "4"
    }
  }, [_c('vx-input', _vm._b({
    attrs: {
      "type": "number",
      "label": "Width"
    },
    model: {
      value: _vm.space.dimension.width,
      callback: function callback($$v) {
        _vm.$set(_vm.space.dimension, "width", $$v);
      },
      expression: "space.dimension.width"
    }
  }, 'vx-input', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "4",
      "md": "4"
    }
  }, [_c('vx-input', _vm._b({
    attrs: {
      "type": "number",
      "label": "Length"
    },
    model: {
      value: _vm.space.dimension.length,
      callback: function callback($$v) {
        _vm.$set(_vm.space.dimension, "length", $$v);
      },
      expression: "space.dimension.length"
    }
  }, 'vx-input', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "4",
      "md": "4"
    }
  }, [_c('vx-input', _vm._b({
    attrs: {
      "type": "number",
      "label": "Height"
    },
    model: {
      value: _vm.space.dimension.height,
      callback: function callback($$v) {
        _vm.$set(_vm.space.dimension, "height", $$v);
      },
      expression: "space.dimension.height"
    }
  }, 'vx-input', _vm.$attrs, false))], 1)], 1)], 1)], 1), _c('SpaceFeatures', {
    attrs: {
      "attributes": _vm.space._attributes,
      "features": _vm.features
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }